* {
  font-family: 'Quicksand', sans-serif;
}
#root {
  height: 100vh;
}
/********** margin padding function start here **************/
.p-0 {
  padding: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.m-0 {
  margin: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.p-4 {
  padding: 4px;
}
.pt-4 {
  padding-top: 4px;
}
.pl-4 {
  padding-left: 4px;
}
.pr-4 {
  padding-right: 4px;
}
.pb-4 {
  padding-bottom: 4px;
}
.m-4 {
  margin: 4px;
}
.mt-4 {
  margin-top: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.m-8 {
  margin: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.m-16 {
  margin: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.m-24 {
  margin: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.m-32 {
  margin: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.m-40 {
  margin: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.m-48 {
  margin: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
/********** margin padding function end here **************/
.m-hide {
  display: block;
}
@media only screen and (max-width: 767px) {
  .m-hide {
    display: none;
  }
}
.d-hide {
  display: none;
}
@media only screen and (max-width: 767px) {
  .d-hide {
    display: block;
  }
}
.d-none {
  display: none;
}
.wrapper {
  padding: 16px;
  padding-bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    font-size: 12px;
    padding: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 16px;
    padding-bottom: 0;
  }
}
.responsive-table {
  width: 100%;
  overflow: auto;
}
.ant-table .ant-table-tbody .anticon {
  font-size: 20px;
  padding: 0 4px;
}
.ant-table .ant-table-tbody .ant-btn {
  margin: 0;
  padding: 0;
  height: auto;
}
.ant-table .ant-table-tbody .ant-btn.border-none {
  border-color: transparent;
}
.pointer {
  cursor: pointer;
}
.grab {
  cursor: grab;
}
/********** text alignment start here **************/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-end {
  text-align: end;
}
.text-justify {
  text-align: justify;
}
/********** text alignment end here **************/
/********** flex box class start here **************/
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.flex-1 {
  flex: 1;
}
.visibility-hidden {
  visibility: hidden;
}
/********** flex box class end here **************/
.width-percent-20 {
  width: 20%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-80 {
  width: 80%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-25 {
  width: 25%;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.site-result-demo-error-icon {
  color: #ff4d4f;
}
.b-0 {
  border: none;
}
/*position class start here*/
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.sticky {
  position: sticky;
}
/*position class end here*/
.header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-card-head-title {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-page-header-heading {
    padding: 0;
    min-height: 48px;
  }
}
#logo {
  height: 64px;
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px dashed #585e75;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}
#logo img {
  height: 32px;
}
.delete-icon {
  color: #121d45;
  cursor: pointer;
}
.anticon[tabindex] {
  color: #121d45;
}
.react-tel-input .invalid-number-message {
  left: 0px;
  top: 35px;
  background: none;
}
.trigger {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}
/*sidebar custom less start here*/
.responsive-logo img {
  width: 44px;
}
.ant-layout-sider.close {
  width: 0;
  max-width: 0;
  min-width: 0;
  left: 0;
}
.ant-layout .ant-layout-sider {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background: #121d45;
}
.ant-layout .ant-layout-sider .side-bar .ant-menu {
  background: transparent;
}
.ant-layout .ant-layout-sider .side-bar .ant-menu .ant-menu-item .anticon {
  font-size: 20px;
}
.ant-layout .ant-layout-sider .side-bar .bottom-logout .ant-menu-light {
  height: auto;
}
.ant-layout .ant-layout-sider .side-bar .ant-menu-light {
  background-color: #121d45;
  color: #fff;
  height: calc(100vh - 117px);
  overflow: auto;
}
.ant-layout .ant-layout-sider .side-bar .ant-menu-light::-webkit-scrollbar-thumb {
  background: #fff;
}
.ant-layout .ant-layout-sider .side-bar .ant-menu-light::-webkit-scrollbar-track {
  background: #121d45;
}
.ant-layout .ant-layout-sider .side-bar .ant-menu-light::-webkit-scrollbar {
  width: 3px;
}
.ant-layout .ant-layout-sider .side-bar .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #fff;
  background-color: #ef3f3e;
}
.ant-layout .ant-layout-sider .side-bar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  color: #fff;
  padding-inline: calc(50% - 16px);
}
.ant-layout .ant-layout-sider .side-bar .ant-menu-submenu .ant-menu-submenu-title:hover {
  color: #fff;
  background: #585e75;
}
.ant-layout .ant-layout-sider .side-bar .ant-menu-submenu .anticon {
  font-size: 20px;
  vertical-align: middle;
  line-height: 40px;
}
.ant-layout .ant-layout-sider .side-bar .ant-menu-submenu-selected {
  background-color: #121d45;
  color: #fff;
}
.ant-layout .ant-layout-sider .side-bar .ant-menu-submenu-selected:hover {
  background-color: #121d45;
}
.ant-menu-submenu-popup.ant-menu-submenu .ant-menu {
  background: #121d45;
}
.ant-menu-submenu-popup.ant-menu-submenu .ant-menu .ant-menu-item-selected {
  background-color: #ef3f3e;
  color: #fff;
}
.ant-menu-submenu-popup.ant-menu-submenu .ant-menu .ant-menu-item-selected:hover {
  color: #fff;
}
.ant-menu-submenu-popup.ant-menu-submenu .ant-menu .ant-menu-item:not(.ant-menu-item-selected):hover {
  color: #fff;
  background: #585e75;
}
.ant-menu-submenu-popup.ant-menu-submenu .ant-menu .ant-menu-item-active {
  color: #fff;
  background: #585e75;
}
.app-header,
.app-main-sidebar .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ant-layout.ant-layout-has-sider {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .ant-layout.ant-layout-has-sider {
    height: calc(100% - 56px);
  }
}
@media only screen and (max-width: 767px) {
  .ant-layout-header {
    padding: 0 16px;
  }
}
header.ant-layout-header.site-layout-sub-header-background.app-header {
  height: 64px;
  padding: 0 32px;
  color: #545454;
  line-height: 64px;
  background: #fff;
}
@media only screen and (max-width: 767px) {
  .overlay-disable {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #000000;
    opacity: 0.6;
    z-index: 11;
  }
}
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  color: #fff;
  background: #585e75;
}
.ant-menu-item {
  color: #fff;
}
.ant-menu-item.ant-menu-item-selected {
  background-color: #ef3f3e;
  color: #fff;
}
/*sidebar custom less end here*/
@media only screen and (max-width: 767px) {
  .ant-layout-footer {
    padding: 16px 50px;
  }
}
/*ant card body scroll class*/
.ant-body-scroll {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.ant-body-scroll .ant-card-head {
  position: sticky;
  z-index: 1;
}
.ant-body-scroll .ant-card-head .ant-select-single {
  width: 260px;
}
.ant-body-scroll .ant-page-header-heading {
  min-height: 48px;
}
.ant-body-scroll > .ant-card-body {
  flex: 1 1;
  height: 100%;
  padding: 0;
  overflow: hidden;
}
.ant-body-scroll > .ant-card-body .card-body-wrapper {
  overflow: auto;
  padding: 24px;
}
.ant-card-small > .ant-card-body .card-body-wrapper {
  padding: 12px;
}
.ant-card .ant-card-actions > li {
  margin: 12px 24px;
}
.title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 5px;
}
.title svg {
  margin-right: 5px;
  font-size: 16px;
}
/*antbody scroll class end here*/
/*fleg dropdown css start here*/
.react-tel-input .form-control {
  padding: 4px 14px 4px 60px;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-size: 14px;
}
/*fleg dropdown css end here*/
td.ant-table-cell p {
  margin: 0;
}
.bottom-logout {
  position: absolute;
  width: 100%;
}
.bottom-logout > div {
  border-top: 1px dashed #585e75;
}
.wrapper {
  /*antd table sorter*/
  /*antd table sorter end here*/
}
.wrapper .ant-table-wrapper .ant-table-column-sorter-up,
.wrapper .ant-table-wrapper .ant-table-column-sorter-down {
  color: #919191;
}
.wrapper .ant-table-wrapper .ant-table-column-sorter-up.active,
.wrapper .ant-table-wrapper .ant-table-column-sorter-down.active {
  color: #ef3f3e;
}
.wrapper .ant-table-wrapper .ant-table-thead > tr > th,
.wrapper .ant-table-wrapper .ant-table-thead > tr > td,
.wrapper .ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters,
.wrapper .ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: #121d45;
  color: #fff;
  text-align: center;
}
.ant-modal .ant-modal-content {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-left::after {
  border-color: #000000;
}
.ant-switch.ant-switch-checked .ant-switch-inner {
  background: #4caf50;
}
.ant-switch .ant-switch-inner {
  background: #ef3f3e;
}
.anticon-filter {
  color: #585e75;
}
.filter .anticon-filter {
  color: #585e75;
}
.filter-active .anticon-filter {
  color: #4caf50;
}
/*ui improvement css start here*/
footer {
  padding: 2px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  footer {
    padding: 4px 16px;
    flex-direction: column;
    gap: 8px;
  }
}
footer .crafted {
  display: flex;
  align-items: center;
  gap: 4px;
}
footer .crafted img {
  height: 28px;
}
@media only screen and (max-width: 767px) {
  .ant-card-head-wrapper .users-sidebar .project-filter-right button {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .ant-layout .ant-layout-sider-has-trigger {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 9;
  }
}
@media only screen and (max-width: 767px) {
  .ant-layout .ant-layout-sider-has-trigger.ant-layout-sider-collapsed {
    left: -80px;
  }
}
.table-card-page {
  height: 100%;
}
.table-card-page .resource-allocation .ant-body-scroll > .ant-card-body .card-body-wrapper {
  height: calc(100vh - 118px);
  overflow: auto;
}
@media only screen and (max-width: 767px) {
  .table-card-page .resource-allocation .ant-body-scroll > .ant-card-body .card-body-wrapper {
    height: 100%;
    overflow: hidden;
  }
}
.table-card-page .resource-allocation .ant-table-wrapper .ant-table .ant-table-header {
  position: sticky;
  top: 0;
  z-index: 9;
}
.table-card-page .resource-allocation .ant-table-cell {
  position: relative;
  overflow: hidden;
}
.table-card-page .resource-allocation .ant-table-cell .ant-form-item {
  margin: 0;
}
.table-card-page .resource-allocation .ant-table-cell .ant-form-item * {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0;
  font-weight: 600;
  font-size: 12px;
  color: #121d45;
  text-align: center;
}
.table-card-page .resource-allocation .ant-table-cell .ant-form-item input {
  border-radius: 0;
}
.table-card-page .resource-allocation .ant-table-cell .ant-form-item input:focus {
  border: 1px solid #000000;
}
.table-card-page .resource-allocation .ant-table-cell .editable-cell-value-wrap {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .table-card-page .resource-allocation .ant-body-scroll > .ant-card-body .card-body-wrapper,
  .table-card-page .resource-allocation .ant-card .ant-card-body .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow: hidden !important;
  }
}
@media only screen and (max-width: 767px) {
  .table-card-page .resource-allocation .ant-table-body {
    max-height: 100% !important;
  }
}
@media only screen and (max-width: 767px) {
  .table-card-page .resource-allocation .ant-body-scroll > .ant-card-body {
    overflow: hidden;
  }
}
@media only screen and (max-width: 767px) {
  .table-card-page .ant-table-wrapper .ant-table-cell-fix-left {
    position: inherit !important;
  }
}
.table-card-page .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
  position: sticky;
  top: 0px;
  z-index: 9;
}
@media only screen and (max-width: 767px) {
  .table-card-page .ant-card .ant-card-head {
    padding: 0 16px;
  }
}
@media only screen and (max-width: 767px) {
  .table-card-page .ant-card .ant-card-head-wrapper {
    flex-wrap: wrap;
    padding: 10px 0;
    gap: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .table-card-page .ant-card .ant-card-head-wrapper .ant-card-head-title {
    flex: 0 0 100%;
  }
}
@media only screen and (max-width: 767px) {
  .table-card-page .ant-card .ant-card-head-wrapper .ant-card-extra {
    width: 100%;
  }
}
.table-card-page .ant-card .ant-card-body {
  height: 100%;
}
.table-card-page .ant-card .ant-card-body .holiday-filter-left + .ant-table-wrapper {
  height: calc(100% - 48px);
}
@media only screen and (max-width: 767px) {
  .table-card-page .ant-card .ant-card-body .holiday-filter-left + .ant-table-wrapper {
    height: calc(100% - 70px);
  }
}
.table-card-page .ant-card .ant-card-body .ant-table-wrapper {
  height: 100%;
}
.table-card-page .ant-card .ant-card-body .ant-table-wrapper .ant-spin-nested-loading {
  height: 100%;
}
.table-card-page .ant-card .ant-card-body .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.table-card-page .ant-card .ant-card-body .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
  flex: 1;
}
.table-card-page .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  border-start-start-radius: 0;
}
.table-card-page .ant-table-wrapper .ant-table-pagination.ant-pagination {
  border-top: 1px solid #f0f0f0;
  margin: 0;
  padding: 16px 0;
}
.table-card-page .ant-table-wrapper .ant-table .ant-table-header {
  border-radius: 0;
}
.table-card-page .ant-body-scroll > .ant-card-body .card-body-wrapper {
  padding: 0;
}
.table-card-page .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child,
.table-card-page .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
  border-radius: 0;
}
.table-card-page .ant-pagination .ant-pagination-item-active {
  background: #121d45;
  color: #fff;
}
.table-card-page .ant-pagination .ant-pagination-item-active a {
  color: #fff;
}
.table-card-page .ant-pagination.ant-pagination-mini .ant-pagination-item,
.table-card-page .ant-pagination.ant-pagination-mini .ant-pagination-next,
.table-card-page .ant-pagination.ant-pagination-mini .ant-pagination-prev {
  margin: 0 5px;
}
.table-card-page .ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
  border: 1px solid #d9d9d9;
}
.table-card-page .ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) a {
  color: #121d45;
  font-weight: 500;
}
.table-card-page .ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
  border: 1px solid #d9d9d9;
}
.table-card-page .ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link {
  border: 1px solid #d9d9d9;
}
.table-card-page .ant-pagination.ant-pagination-mini .ant-pagination-options {
  margin-inline-start: 5px;
}
@media only screen and (max-width: 767px) {
  .table-card-page .ant-pagination.ant-pagination-mini .ant-pagination-options {
    display: block;
  }
}
.table-card-page .ant-table-wrapper .ant-table.ant-table-small {
  font-size: 12px;
  font-weight: 600;
  overflow: auto;
  color: #121d45;
}
.table-card-page .project-filter .ant-select {
  min-width: 150px;
  width: 100%;
}
.table-card-page .ant-table-wrapper .ant-table {
  border-radius: 0;
}
.table-card-page .ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none;
}
.table-card-page .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:nth-last-child(2) {
  border-inline-end: 0;
}
.ant-layout .ant-layout-sider-light .ant-layout-sider-trigger {
  color: #fff;
  background: #585e75;
  position: absolute;
  top: 20px;
  right: -10px;
  width: 20px !important;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 767px) {
  .ant-layout .ant-layout-sider-light .ant-layout-sider-trigger {
    display: none;
  }
}
/*ui improvement css end here*/
.default-tag-width {
  width: 80px;
  min-width: 80px;
}
.expanding-tag-width {
  width: fit-content;
  min-width: 80px;
  transition: width 0.3s ease-in-out;
}
.filter-filled-data {
  color: #ef3f3e;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  font-weight: 600;
}
.profile-avatar {
  display: flex;
  align-items: center;
  gap: 12px;
}
.profile-avatar h4 {
  margin-top: 0;
}
.profile-avatar h4 span {
  color: #7f7f7f;
  font-size: 16px;
  margin-left: 5px;
}
.profile-avatar .job-title {
  color: #4b4b4b;
  font-size: 14px;
  font-weight: 600;
}
.ant-descriptions .ant-descriptions-item-label,
.ant-descriptions .ant-descriptions-item-content {
  font-weight: 500;
}
.lw-logo {
  width: 110px;
  height: auto;
}
.ant-form-large .ant-form-item .ant-form-item-label > label {
  height: auto;
  font-weight: 500;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #121d45;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.email-signature-page {
  height: 100%;
}
.email-signature-page .ant-card {
  height: 100%;
}
.email-signature-page .ant-card-body {
  padding: 16px;
  overflow: auto;
}
@media only screen and (max-width: 767px) {
  .email-signature-page .ant-card-body form .w-450 {
    overflow: auto;
  }
}
@media only screen and (max-width: 767px) {
  .email-signature-page .ant-card-head {
    padding: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .email-signature-page .ant-card-head .ant-card-head-wrapper {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 767px) {
  .email-signature-page .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
    flex: 0 0 100%;
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .email-signature-page .ant-card-head .ant-card-head-wrapper .ant-card-extra {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .email-signature-page .ant-card-head .ant-card-head-wrapper .ant-card-extra button {
    width: 100%;
  }
}
header {
  display: none;
}
@media only screen and (max-width: 767px) {
  header {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;
  }
}
header .toggle-btn {
  width: 40px;
}
header .toggle-btn svg {
  vertical-align: middle;
}
header .toggle-btn .humber-icon {
  font-size: 24px;
  color: #121d45;
}
@media only screen and (max-width: 767px) {
  header .logo img {
    width: 40px;
    vertical-align: middle;
  }
}
.ant-btn {
  font-weight: 500;
  box-shadow: none;
}
.ant-menu-inline-collapsed > .ant-menu-item {
  padding-inline: calc(50% - 16px);
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  vertical-align: middle;
  line-height: 40px;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon svg {
  vertical-align: middle;
}
.react-resizable {
  position: relative;
  background-clip: padding-box;
}
.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}
.card-body-wrapper .ant-divider {
  margin: clamp(10px, 1.5vw, 24px) 0;
}
@media only screen and (max-width: 1450px) {
  .card-body-wrapper .card-padding {
    padding: 0px;
  }
  .card-body-wrapper .ant-space {
    padding-bottom: 6px;
  }
  .card-body-wrapper .ant-divider {
    margin: clamp(10px, 0.5vw, 24px) 0;
  }
  .card-body-wrapper .ant-descriptions-small .ant-descriptions-row > th,
  .card-body-wrapper .ant-descriptions-small .ant-descriptions-row > td {
    padding-bottom: 4px;
  }
  .card-body-wrapper .ant-descriptions .ant-descriptions-header {
    margin-bottom: 0px;
  }
  .card-body-wrapper .ant-descriptions .ant-descriptions-row > td {
    padding-bottom: 0px;
  }
  .card-body-wrapper .ant-form-item {
    margin-bottom: 0px;
  }
}
.resource-allocation .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.resource-allocation .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container .ant-table-body {
  overflow: auto;
  flex: 1;
}
.add-new-tag {
  border-style: dashed;
}
.add-new-tag:hover {
  cursor: pointer;
}
.custom-modal-button .ant-btn-primary {
  background-color: #121d45;
  color: #fff;
}
.custom-modal-button .ant-btn-primary:not(:disabled):hover {
  background-color: #222e52;
}
.custom-modal-button .ant-btn:not(:disabled):focus-visible {
  outline: none;
}
.custom-modal-button .ant-btn-default:not(:disabled):hover {
  color: #121d45;
  border-color: #121d45;
}
.link-custom-width a {
  display: block;
  width: 100%;
}
.expandable-row-table .ant-table-thead .ant-table-cell.ant-table-row-expand-icon-cell {
  position: sticky;
  top: 0;
  z-index: 9;
}
@media print {
  .ant-layout .ant-layout-sider {
    display: none;
  }
  .resume-container {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  .wrapper {
    width: auto;
    height: auto;
    overflow: visible;
  }
  .employee-resume-container .ant-card .ant-card-head {
    display: none;
  }
  .employee-resume-container .ant-card .ant-card-body {
    width: auto;
    height: auto;
    overflow: visible;
  }
  .employee-resume-container .ant-card .ant-card-body .ant-tabs .ant-tabs-nav {
    display: none;
  }
  .employee-resume-container .ant-card .ant-card-body .ant-tabs .ant-tabs-content-holder {
    border: none;
  }
  .employee-resume-container .ant-card .ant-card-body .ant-tabs .ant-tabs-tabpane {
    padding: 0;
  }
  .employee-resume-container .employee-resume-page .ant-tabs .resume-tab-pane-sidebar {
    display: none;
  }
  footer {
    display: none;
  }
}
.profile-menu .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}
/*ui improvement css end here*/
:root {
  --body-fonts: 'Quicksand', sans-serif;
}
/*breakpoint mixin start here*/
/*breakpoint mixin end here*/
/*typography css end here*/
/**https://github.com/ant-design/ant-design/blob/master/components/style/themes/variable.less**/
