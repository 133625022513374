:root {
  --body-fonts: 'Quicksand', sans-serif;
}

/*breakpoint mixin start here*/
@screen1450: ~'only screen and (max-width: 1450px)';
@screen1150: ~'only screen and (max-width: 1150px)';
@screen1024: ~'only screen and (max-width: 1024px)';
@screen991: ~'only screen and (max-width: 991px)';
@screen767: ~'only screen and (max-width: 767px)';
@screen540: ~'only screen and (max-width: 540px)';
@screen375: ~'only screen and (max-width: 375px)';
@screen320: ~'only screen and (max-width: 320px)';
/*breakpoint mixin end here*/

// ColorPicker Variables
@white-color: #fff;
@black-color: #000000;

//global style variables
@lw-primary: #121d45;
@secondary-color: #585e75;
@lw-red: #ef3f3e;
@lw-red-highlight: #ffe8e8;
@button-hover-color: #222e52;
@lw-green: #4caf50;
@lw-green-light: #d9ffda;
@warning: #ff9800;
@pending-row-color: #ffd290;
@text-color: @white-color;
@approved-row: #fdd1d4;
@approved-row-color: #fba4aa;
@text-color-secondary: #585e75;
@heading-color: #585e75;
@header-text-color: #262626;
@layout-header-background: @white-color;
@layout-header-background-dark: @lw-primary;
@body-background: @white-color;
@nav-bar-bg: @white-color;
@nav-bar-text-color: @text-color;
@blue: #85ceff;
@nav-dark-bg: @lw-primary;
@nav-dark-text-color: @white-color;
@menu-dark-bg: @nav-dark-bg;
@menu-dark-color: @nav-dark-text-color;
@menu-dark-highlight-color: @secondary-color;
@menu-dark-arrow-color: @nav-dark-text-color;
@hor-nav-text-color: @white-color;
@input-disable: #6e6e6e;
@app-header-bg: #545454;
@mobile-input: #d9d9d9;
@row-hover-color: #e8e8e8;
@pagination-border: #f0f0f0;
@avtar-color: #7f7f7f;
@avtar-title: #4b4b4b;
@scrollbar-color: #f1f1f1;
@scrollbar-hover: #555;
@leave-pending: #ffbb57;
@cappital-tag: #8f6d73;
@client-tag: #6e7f8d;
@partnership-tag: #6e8762;
@product-tag: #c9c27b;
@lab-tag: #6e6e6e;
@paid-leave: #64c3d1;
@paid-leave-gradient: rgba(100, 195, 209, 0.4);
@unpaid-leave: #c2b89d;
@unpaid-leave-gradient: rgba(194, 184, 157, 0.4);
@work-from-home: #9785c2;
@work-from-home-gradient: rgba(151, 133, 194, 0.4);
@multiple-leave-on-day: #fa5f5f;
@holiday: #98b433;
@weekdays: #fbc02d;
@border-radius-base: 4px;
@border-radius-sm: 4px;
@border-radius-lg: 10px;
@layout-header-background: @white-color;
@layout-header-padding: 0 32px;
@card-actions-li-margin: 12px 24px;

@heading-1-size: clamp(25px, 4.6vw, 38px);
@heading-2-size: clamp(20px, 4vw, 30px);
@heading-3-size: clamp(18px, 3.8vw, 24px);
@heading-4-size: clamp(16px, 3vw, 20px);
@heading-5-size: clamp(14px, 2.5vw, 16px);

/*typography css end here*/
//*all variable list link are here*//
/**https://github.com/ant-design/ant-design/blob/master/components/style/themes/variable.less**/
