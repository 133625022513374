@import './variable.less';

* {
  font-family: 'Quicksand', sans-serif;
}

#root {
  height: 100vh;
}

//=========================================
/********** margin padding function start here **************/
//=========================================
.for(@i, @n) {
  .-each(@i);
}

.for(@n) when (isnumber(@n)) {
  .for(1, @n);
}

.for(@i, @n) when not (@i =@n) {
  .for((@i + (@n - @i) / abs(@n - @i)), @n);
}

//--------  End of for loop  ---------//
//=========================================
//              FOR EACH LOOP             =
//=========================================
.for(@array) when (default()) {
  .for-impl_(length(@array));
}

.for-impl_(@i) when (@i > 1) {
  .for-impl_((@i - 1));
}

.for-impl_(@i) when (@i > 0) {
  .-each(extract(@array, @i));
}

//--------  End of for each loop  ---------//

@values-array: 0, 4, 8, 16, 24, 32, 40, 48;

// init functions
.make-space() {
  .for(@values-array);

  .-each(@value) {
    .p-@{value} {
      padding: ~'@{value}px';
    }

    .pt-@{value} {
      padding-top: ~'@{value}px';
    }

    .pl-@{value} {
      padding-left: ~'@{value}px';
    }

    .pr-@{value} {
      padding-right: ~'@{value}px';
    }

    .pb-@{value} {
      padding-bottom: ~'@{value}px';
    }

    .m-@{value} {
      margin: ~'@{value}px';
    }

    .mt-@{value} {
      margin-top: ~'@{value}px';
    }

    .ml-@{value} {
      margin-left: ~'@{value}px';
    }

    .mr-@{value} {
      margin-right: ~'@{value}px';
    }

    .mb-@{value} {
      margin-bottom: ~'@{value}px';
    }
  }
}

.make-space();

//=========================================
/********** margin padding function end here **************/
//=========================================
.m-hide {
  display: block;

  @media @screen767 {
    display: none;
  }
}

.d-hide {
  display: none;

  @media @screen767 {
    display: block;
  }
}

.d-none {
  display: none;
}

.wrapper {
  padding: 16px;
  padding-bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media @screen991 {
    font-size: 12px;
    padding: 24px;
  }

  @media @screen767 {
    padding: 16px;
    padding-bottom: 0;
  }
}

.responsive-table {
  width: 100%;
  overflow: auto;
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
      &.border-none {
        border-color: transparent;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

//=========================================
/********** text alignment start here **************/
//=========================================
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

.text-justify {
  text-align: justify;
}
//=========================================
/********** text alignment end here **************/
//=========================================

//=========================================
/********** flex box class start here **************/
//=========================================

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.flex-1 {
  flex: 1;
}
.visibility-hidden {
  visibility: hidden;
}

//=========================================
/********** flex box class end here **************/
//=========================================

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-80 {
  width: 80%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

//width classes

.site-result-demo-error-icon {
  color: @colorError;
}

//border style
.b-0 {
  border: none;
}

//border style

/*position class start here*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

/*position class end here*/

//CUSTOM STYLE CLASSES END

.header-wrapper {
  .d-flex;
  .justify-between;
  .align-center;
}

.sticky-action-form {
  .ant-card-head-title {
    @media @screen767 {
      padding: 0;
    }
  }

  .ant-page-header-heading {
    @media @screen767 {
      padding: 0;
      min-height: 48px;
    }
  }
}

#logo {
  height: 64px;
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px dashed @secondary-color;
  .d-flex;
  .align-center;
  .justify-center;
  color: @white-color;

  img {
    height: 32px;
  }
}

.delete-icon {
  color: @lw-primary;
  cursor: pointer;
}

.anticon[tabindex] {
  color: @lw-primary;
}

.react-tel-input .invalid-number-message {
  left: 0px;
  top: 35px;
  background: none;
}

.trigger {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

/*sidebar custom less start here*/

.responsive-logo img {
  width: 44px;
}

.ant-layout-sider.close {
  width: 0;
  max-width: 0;
  min-width: 0;
  left: 0;
}

.ant-layout {
  .ant-layout-sider {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    background: @lw-primary;

    .side-bar {
      .ant-menu {
        background: transparent;

        .ant-menu-item {
          .anticon {
            font-size: 20px;
          }
        }
      }
      .bottom-logout {
        .ant-menu-light {
          height: auto;
        }
      }
      .ant-menu-light {
        background-color: @lw-primary;
        color: @white-color;
        height: calc(100vh - 117px);
        overflow: auto;
        &::-webkit-scrollbar-thumb {
          background: @white-color;
        }
        &::-webkit-scrollbar-track {
          background: @lw-primary;
        }
        &::-webkit-scrollbar {
          width: 3px;
        }
      }
      .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
        color: @white-color;
        background-color: @lw-red;
      }
      .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
        color: @white-color;
        padding-inline: calc(50% - 16px);
      }
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          &:hover {
            color: @white-color;
            background: @secondary-color;
          }
        }
        .anticon {
          font-size: 20px;
          vertical-align: middle;
          line-height: 40px;
        }
      }
      .ant-menu-submenu-selected {
        background-color: @lw-primary;
        color: @white-color;
        &:hover {
          background-color: @lw-primary;
        }
      }
    }
  }
}
.ant-menu-submenu-popup.ant-menu-submenu {
  .ant-menu {
    background: @lw-primary;
    .ant-menu-item-selected {
      background-color: @lw-red;
      color: @white-color;
      &:hover {
        color: @white-color;
      }
    }
    .ant-menu-item:not(.ant-menu-item-selected):hover {
      color: @white-color;
      background: @secondary-color;
    }
    .ant-menu-item-active {
      color: @white-color;
      background: @secondary-color;
    }
  }
}

.app-header,
.app-main-sidebar .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ant-layout.ant-layout-has-sider {
  height: 100%;

  @media @screen767 {
    height: calc(100% - 56px);
  }
}

.ant-layout-header {
  @media @screen767 {
    padding: 0 16px;
  }
}

header.ant-layout-header.site-layout-sub-header-background.app-header {
  height: 64px;
  padding: 0 32px;
  color: @app-header-bg;
  line-height: 64px;
  background: @white-color;
}

.overlay-disable {
  @media @screen767 {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: @black-color;
    opacity: 0.6;
    z-index: 11;
  }
}

.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  color: @white-color;
  background: @secondary-color;
}

.ant-menu-item {
  color: @white-color;

  &.ant-menu-item-selected {
    background-color: @lw-red;
    color: @white-color;
  }
}

/*sidebar custom less end here*/
.ant-layout-footer {
  @media @screen767 {
    padding: 16px 50px;
  }
}

/*ant card body scroll class*/
.ant-body-scroll {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
  width: 100%;
  overflow: auto;

  .ant-card-head {
    position: sticky;
    z-index: 1;

    .ant-select-single {
      width: 260px;
    }
  }

  .ant-page-header-heading {
    min-height: 48px;
  }

  > .ant-card-body {
    flex: 1 1;
    height: 100%;
    padding: 0;
    overflow: hidden;

    .card-body-wrapper {
      overflow: auto;
      padding: 24px;
    }
  }
}

.ant-card-small {
  > .ant-card-body {
    .card-body-wrapper {
      padding: 12px;
    }
  }
}

.ant-card .ant-card-actions > li {
  margin: 12px 24px;
}

.title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 5px;

  svg {
    margin-right: 5px;
    font-size: 16px;
  }
}

/*antbody scroll class end here*/
/*fleg dropdown css start here*/
.react-tel-input .form-control {
  padding: 4px 14px 4px 60px;
  width: 100%;
  position: relative;
  background-color: @white-color;
  border: 1px solid @mobile-input;
  border-radius: 6px;
  font-size: 14px;
}

/*fleg dropdown css end here*/

//preventing default margin
td.ant-table-cell p {
  margin: 0;
}

.bottom-logout {
  position: absolute;
  width: 100%;

  > div {
    border-top: 1px dashed @secondary-color;
  }
}

.wrapper {
  /*antd table sorter*/
  .ant-table-wrapper .ant-table-column-sorter-up,
  .ant-table-wrapper .ant-table-column-sorter-down {
    color: rgb(145, 145, 145);
  }

  .ant-table-wrapper .ant-table-column-sorter-up.active,
  .ant-table-wrapper .ant-table-column-sorter-down.active {
    color: @lw-red;
  }

  /*antd table sorter end here*/
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td,
  .ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters,
  .ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: @lw-primary;
    color: @white-color;
    text-align: center;
  }
}

.ant-modal .ant-modal-content {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

//antd-divider
.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-left::after {
  border-color: @black-color;
}

//toggle switch
.ant-switch.ant-switch-checked .ant-switch-inner {
  background: @lw-green;
}

.ant-switch .ant-switch-inner {
  background: @lw-red;
}

//table action column

.anticon-filter {
  color: @secondary-color;
}

.filter {
  .anticon-filter {
    color: @secondary-color;
  }
}

.filter-active {
  .anticon-filter {
    color: @lw-green;
  }
}

/*ui improvement css start here*/

footer {
  padding: 2px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media @screen767 {
    padding: 4px 16px;
    flex-direction: column;
    gap: 8px;
  }

  .crafted {
    display: flex;
    align-items: center;
    gap: 4px;

    img {
      height: 28px;
    }
  }
}

.ant-card-head-wrapper {
  .users-sidebar {
    .project-filter-right {
      button {
        @media @screen767 {
          width: 100%;
        }
      }
    }
  }
}

.ant-layout .ant-layout-sider-has-trigger {
  @media @screen767 {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 9;
  }

  &.ant-layout-sider-collapsed {
    @media @screen767 {
      left: -80px;
    }
  }
}

.table-card-page {
  height: 100%;

  .resource-allocation {
    .ant-body-scroll > .ant-card-body .card-body-wrapper {
      height: calc(100vh - 118px);
      overflow: auto;

      @media @screen767 {
        height: 100%;
        overflow: hidden;
      }
    }

    .ant-table-wrapper .ant-table .ant-table-header {
      position: sticky;
      top: 0;
      z-index: 9;
    }

    .ant-table-cell {
      position: relative;
      overflow: hidden;

      .ant-form-item {
        margin: 0;

        * {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          padding: 0;
          font-weight: 600;
          font-size: 12px;
          color: @lw-primary;
          text-align: center;
        }

        input {
          border-radius: 0;

          &:focus {
            border: 1px solid @black-color;
          }
        }
      }

      .editable-cell-value-wrap {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-body-scroll > .ant-card-body .card-body-wrapper,
    .ant-card
      .ant-card-body
      .ant-table-wrapper
      .ant-spin-nested-loading
      .ant-spin-container
      .ant-table {
      @media @screen767 {
        overflow: hidden !important; //override antd inline css
      }
    }

    .ant-table-body {
      @media @screen767 {
        max-height: 100% !important; //override antd inline css
      }
    }

    .ant-body-scroll > .ant-card-body {
      @media @screen767 {
        overflow: hidden;
      }
    }
  }

  .ant-table-wrapper .ant-table-cell-fix-left {
    @media @screen767 {
      position: inherit !important; //override antd inline css
    }
  }

  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    position: sticky;
    top: 0px;
    z-index: 9;
  }

  .ant-card {
    .ant-card-head {
      @media @screen767 {
        padding: 0 16px;
      }
    }

    .ant-card-head-wrapper {
      @media @screen767 {
        flex-wrap: wrap;
        padding: 10px 0;
        gap: 10px;
      }

      .ant-card-head-title {
        @media @screen767 {
          flex: 0 0 100%;
        }
      }

      .ant-card-extra {
        @media @screen767 {
          width: 100%;
        }
      }
    }

    .ant-card-body {
      height: 100%;

      .holiday-filter-left {
        + .ant-table-wrapper {
          height: calc(100% - 48px);

          @media @screen767 {
            height: calc(100% - 70px);
          }
        }
      }

      .ant-table-wrapper {
        height: 100%;

        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;
            display: flex;
            flex-direction: column;

            .ant-table {
              flex: 1;
            }
          }
        }
      }
    }
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    border-top: 1px solid @pagination-border;
    margin: 0;
    padding: 16px 0;
  }

  .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0;
  }

  .ant-body-scroll > .ant-card-body .card-body-wrapper {
    padding: 0;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child,
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-radius: 0;
  }

  .ant-pagination .ant-pagination-item-active {
    background: @lw-primary;
    color: @white-color;

    a {
      color: @white-color;
    }
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-item,
  .ant-pagination.ant-pagination-mini .ant-pagination-next,
  .ant-pagination.ant-pagination-mini .ant-pagination-prev {
    margin: 0 5px;
  }

  .ant-pagination.ant-pagination-mini
    .ant-pagination-item:not(.ant-pagination-item-active) {
    border: 1px solid @mobile-input;

    a {
      color: @lw-primary;
      font-weight: 500;
    }
  }

  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
    border: 1px solid @mobile-input;
  }

  .ant-pagination.ant-pagination-mini
    .ant-pagination-next
    .ant-pagination-item-link {
    border: 1px solid @mobile-input;
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-options {
    margin-inline-start: 5px;

    @media @screen767 {
      display: block;
    }
  }

  .ant-table-wrapper .ant-table.ant-table-small {
    font-size: 12px;
    font-weight: 600;
    overflow: auto;
    color: @lw-primary;
  }

  .project-filter {
    .ant-select {
      min-width: 150px;
      width: 100%;
    }
  }

  .ant-table-wrapper .ant-table {
    border-radius: 0;
  }

  .ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
  }

  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th:nth-last-child(2) {
    border-inline-end: 0;
  }
}

.ant-layout .ant-layout-sider-light {
  .ant-layout-sider-trigger {
    color: @white-color;
    background: @secondary-color;
    position: absolute;
    top: 20px;
    right: -10px;
    width: 20px !important; //override antd inline css
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 10%);

    @media @screen767 {
      display: none;
    }
  }
}

/*ui improvement css end here*/
.default-tag-width {
  width: 80px;
  min-width: 80px;
}

.expanding-tag-width {
  width: fit-content;
  min-width: 80px;
  transition: width 0.3s ease-in-out;
}

.filter-filled-data {
  color: @lw-red;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  font-weight: 600;
}

.profile-avatar {
  display: flex;
  align-items: center;
  gap: 12px;

  h4 {
    margin-top: 0;

    span {
      color: @avtar-color;
      font-size: 16px;
      margin-left: 5px;
    }
  }

  .job-title {
    color: @avtar-title;
    font-size: 14px;
    font-weight: 600;
  }
}

.ant-descriptions {
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    font-weight: 500;
  }
}

.lw-logo {
  width: 110px;
  height: auto;
}

.ant-form-large .ant-form-item .ant-form-item-label > label {
  height: auto;
  font-weight: 500;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: @scrollbar-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: @lw-primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: @scrollbar-hover;
}

.email-signature-page {
  height: 100%;

  .ant-card {
    height: 100%;
  }

  .ant-card-body {
    padding: 16px;
    overflow: auto;

    form {
      .w-450 {
        @media @screen767 {
          overflow: auto;
        }
      }
    }
  }

  .ant-card-head {
    @media @screen767 {
      padding: 16px;
    }

    .ant-card-head-wrapper {
      @media @screen767 {
        flex-wrap: wrap;
      }

      .ant-card-head-title {
        @media @screen767 {
          flex: 0 0 100%;
          margin-bottom: 8px;
        }
      }

      .ant-card-extra {
        @media @screen767 {
          width: 100%;
        }

        button {
          @media @screen767 {
            width: 100%;
          }
        }
      }
    }
  }
}

header {
  display: none;

  @media @screen767 {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;
  }

  .toggle-btn {
    width: 40px;

    svg {
      vertical-align: middle;
    }

    .humber-icon {
      font-size: 24px;
      color: @lw-primary;
    }
  }

  .logo {
    img {
      @media @screen767 {
        width: 40px;
        vertical-align: middle;
      }
    }
  }
}

.ant-btn {
  font-weight: 500;
  box-shadow: none;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding-inline: calc(50% - 16px);

  .anticon {
    vertical-align: middle;
    line-height: 40px;

    svg {
      vertical-align: middle;
    }
  }
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.card-body-wrapper {
  .ant-divider {
    margin: clamp(10px, 1.5vw, 24px) 0;
  }

  @media @screen1450 {
    .card-padding {
      padding: 0px;
    }

    .ant-space {
      padding-bottom: 6px;
    }

    .ant-divider {
      margin: clamp(10px, 0.5vw, 24px) 0;
    }

    .ant-descriptions-small .ant-descriptions-row > th,
    .ant-descriptions-small .ant-descriptions-row > td {
      padding-bottom: 4px;
    }

    .ant-descriptions {
      .ant-descriptions-header {
        margin-bottom: 0px;
      }

      .ant-descriptions-row > td {
        padding-bottom: 0px;
      }
    }

    .ant-form-item {
      margin-bottom: 0px;
    }
  }
}

.resource-allocation {
  .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ant-table-body {
      overflow: auto;
      flex: 1;
    }
  }
}
.add-new-tag {
  border-style: dashed;
  &:hover {
    cursor: pointer;
  }
}
.custom-modal-button {
  .ant-btn-primary {
    background-color: @lw-primary;
    color: @white-color;
  }
  .ant-btn-primary:not(:disabled):hover {
    background-color: @button-hover-color;
  }
  .ant-btn:not(:disabled):focus-visible {
    outline: none;
  }
  .ant-btn-default:not(:disabled):hover {
    color: @lw-primary;
    border-color: @lw-primary;
  }
}

.link-custom-width {
  a {
    display: block;
    width: 100%;
  }
}

.expandable-row-table {
  .ant-table-thead .ant-table-cell.ant-table-row-expand-icon-cell {
    position: sticky;
    top: 0;
    z-index: 9;
  }
}

@media print {
  .ant-layout .ant-layout-sider {
    display: none;
  }
  .resume-container {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  .wrapper {
    width: auto;
    height: auto;
    overflow: visible;
  }
  .employee-resume-container {
    .ant-card {
      .ant-card-head {
        display: none;
      }
      .ant-card-body {
        width: auto;
        height: auto;
        overflow: visible;
        .ant-tabs {
          .ant-tabs-nav {
            display: none;
          }
          .ant-tabs-content-holder {
            border: none;
          }
          .ant-tabs-tabpane {
            padding: 0;
          }
        }
      }
    }
    .employee-resume-page {
      .ant-tabs {
        .resume-tab-pane-sidebar {
          display: none;
        }
      }
    }
  }
  footer {
    display: none;
  }
}

.profile-menu {
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }
}

/*ui improvement css end here*/
